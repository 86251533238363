import React from "react";
import { Element } from "react-scroll";
import SectionHome from "../sectionHome/SectionHome";

const HomeOneAbout = () => {
  return (
    <Element
      name="about-us"
      className="edu-about-area about-style-1 bg-color-white"
    >
      <div className="container" id="home-about">
        <div className="row g-5">
          <img
            className="image-1"
            src="/images/about/about-09/imagen_abajo_slider_home.png"
            alt="About Main Thumb"
          />
        </div>
        <div className="row g-5" id="images-text-container-home">
          <div className="col-lg-3 col-xl-3 col-images" id="home-image-left">
            <img
            id="image-home-left"
              src="/images/about/about-09/uru_it_home.png"
              alt="About Images"
            />
          </div>
          <div className="col-lg-6 col-xl-6" id="text-container">
            <div className="inner">
              <SectionHome title="Clases de italiano" />
              <div className="about-feature-list">
                <div className="our-feature">
                  <div className="icon">
                    <i className="icon-Hand---Book"></i>
                  </div>
                  <div className="feature-content">
                    <h6 className="feature-title">
                      Cursos presenciales y online
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-xl-3 col-images" id="logo-div-img">
            <img src="/images/logo/logo.png" alt="About Images" />
          </div>
        </div>
      </div>
    </Element>
  );
};

export default HomeOneAbout;
