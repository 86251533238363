import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const AboutUsThreeMission = () => {
  return (
    <div className="eduvibe-about-three-mission edu-mission-vision-area edu-section-gap border-bottom-1 bg-color-white">
      <div className="container eduvibe-animated-shape">
        <div className="row">
          <div className="col-lg-12">
            {/* <div className="thumbnail mb--50">
                            <div className="shape-image image-1">
                                <Parallax speed={1} y={[0, 40]} tagOuter="figure">
                                    <img src="/images/mission/pexels-startup-stock-photos-7096-min.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                            <div className="shape-image image-2">
                                <Parallax speed={1} y={[0, -20]} tagOuter="figure">                                
                                    <img src="/images/mission/pexels-fauxels-3184295-min.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                            <div className="shape-image image-3">
                                <Parallax speed={1} y={[0, 30]} tagOuter="figure">
                                <img src="/images/mission/pexels-thisisengineering-3862153-min.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                            <div className="shape-image image-4">
                                <Parallax speed={1} y={[0, -18]} tagOuter="figure">
                                    <img src="/images/mission/pexels-buro-millennial-1438072-min.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                        </div> */}

            <Carousel showArrows={true} dynamicHeight={true}>
              <div>
                <img src="/images/mission/Sede_IIC_Centro_1.png" alt="Sede Centro Puerta" />
              </div>
              <div>
                <img src="/images/mission/Sede_IIC_Centro_2.png" alt="Sede Centro" />
              </div>
              <div>
                <img src="/images/mission/Sede_ORT_Pocitos_1.png" alt="Sede Centro Pocitos" />
              </div>
              <div>
                <img src="/images/mission/Sede_ORT_Pocitos_2.png" alt="Sede Centro Ombu" />
              </div>
            </Carousel>
          </div>
        </div>

        {/* <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="animated-image shape-image-1">
                        <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-1">
                        <span className="shape-dot"></span>
                    </div>
                    <div className="shape shape-2">
                        <span className="shape-dot"></span>
                    </div>
                </div> */}
      </div>

      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6">
            <div className="our-mission">
              <div className="section-title text-start">
                <img src="/images/mission/Quienes_somos.png" alt="Quienes Somos" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="our-vision">
              <div className="section-title text-start">
                <img src="/images/mission/Chi_siamo.png" alt="Chi Siamo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsThreeMission;
