import React from 'react';
import { Link } from 'react-router-dom';
import ScrollTopButton from './ScrollTopButton';

const FooterOne = () => {
    return (
        <>
            <footer className="eduvibe-footer-one edu-footer footer-style-default">
                <div className="footer-top">
                    <div className="container eduvibe-animated-shape">
                        <div className="row g-5">
                            {/* <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="edu-footer-widget">
                                    <div className="logo">
                                        <Link to={process.env.PUBLIC_URL + "/"}>
                                            <img className="logo-light" src="/images/logo/logo-white.png" alt="Footer Logo" />
                                        </Link>
                                    </div>
                                    <p className="description">It is a long established fact that a reader will be distracted by the readable content of a
                                        page when looking at its layout. The point of using Lorem Ipsum.</p>
                                    <ul className="social-share">
                                        <li><a href="#"><i className="icon-Fb"></i></a></li>
                                        <li><a href="#"><i className="icon-linkedin"></i></a></li>
                                        <li><a href="#"><i className="icon-Pinterest"></i></a></li>
                                        <li><a href="#"><i className="icon-Twitter"></i></a></li>
                                    </ul>
                                </div>
                            </div> */}

                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget explore-widget">
                                    <h5 className="widget-title">Navegue</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><Link to="/home-1"><i className="icon-Double-arrow"></i>Inicio</Link></li>
                                            <li><Link to="/about-us-3"><i className="icon-Double-arrow"></i>Cooperativa</Link></li>
                                            <li><Link to="/course-4"><i className="icon-Double-arrow"></i>Cursos</Link></li>
                                            <li><Link to="/contact-us"><i className="icon-Double-arrow"></i>Contacto</Link></li>
                                        </ul>
                                    </div>
                                    <ul className="social-share">
                                        {/* <li><a href="#"><i className="icon-Fb"></i></a></li> */}
                                        {/* <li><a href="#"><i className="icon-linkedin"></i></a></li> */}
                                        {/* <li><a href="#"><i className="icon-Twitter"></i></a></li> */}
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget quick-link-widget">
                                    <h5 className="widget-title">Links útiles</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><a href="https://ambmontevideo.esteri.it/ambasciata_montevideo/es/" target="_blank"><i className="icon-Double-arrow"></i>Embajada Italiana</a></li>
                                            <li><a href="https://iicmontevideo.esteri.it/iic_montevideo/it" target="_blank"><i className="icon-Double-arrow"></i>Istituto Italiano di Cultura</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget">
                                    <h5 className="widget-title">Contacto</h5>
                                    <div className="inner">
                                        <div className="widget-information">
                                            <ul className="information-list">
                                                <li><i className="icon-map-pin-line"></i>Paraguay 1177, Montevideo, Uruguay.
                                                </li>
                                                <li><i className="icon-mail-line-2"></i><a target="_blank" href="mailto:adocsecretaria@gmail.com">adocsecretaria@gmail.com</a></li>
                                                <li><i className="icon-phone-fill"></i><a href="tel:095750717">095750717</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-21-01.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-35.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTopButton />
        </>
    )
}

export default FooterOne;