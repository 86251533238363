import React from 'react';
import Layout from '../../common/Layout';
import SEO from '../../common/SEO';
import BannerTwo from '../../components/banner/BannerTwo';
import HomeOneAbout from '../../components/home-one/HomeOneAbout';
import CallToActionOne from '../../components/cta/CallToActionOne';


const HomeOne = () => {
    return (
        <>
            <SEO title="Inicio" />
            <Layout>

            <BannerTwo />
            
            <HomeOneAbout />

            {/* <HomeOneInstructor /> */}
            <CallToActionOne />
            </Layout>
        </>
    )
}

export default HomeOne;