import React from "react";

const SectionHome = ({ slogan, title, classes }) => {
  return (
    <div className={`section-title ${classes ? classes : ""}`}>
      <div className="inner-section-title">
      {/* <img className="image-1" src="/images/about/about-09/uru_it_home.png" alt="About Main Thumb" /> */}
        <h3 className="title" dangerouslySetInnerHTML={{ __html: title }}></h3>
        {/* <img className="image-1" src="/images/logo/logo.png" alt="About Main Thumb" /> */}
      </div>
      <hr />
    </div>
  );
};
export default SectionHome;
