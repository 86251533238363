import React from 'react';
import { Link } from 'react-router-dom';

const BannerTwo = () => {
    return (
        <div className="slider-area banner-style-2 bg-image d-flex align-items-center">
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="inner">
                            <div className="content">
                                {/* <span className="pre-title">Better Learning Future With Us</span> */}
                                {/* <h1 className="title">TUTTE LE STRADE PORTANO A...</h1> */}
                                {/* <p className="description">It is long established that a reader will be distracted by the <br /> readable popular and best content.</p> */}
                                {/* <div className="read-more-btn">
                                    <Link className="edu-btn" id='slider-btn-edu' to="/cursos">Cursos de italiano segundo semestre 2022 <i  className="icon-arrow-right-line-right"></i></Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerTwo;