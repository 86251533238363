import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../sectionTitle/SectionTitle';

const CallToActionOne = () => {
    return (
        <div className="edu-newsletter-area bg-image newsletter-style-3 bg-color-primary">
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6">
                        <div className="inner">
                        <video width="600" height="600" controls>
                    <source src="/videos/video_home.mp4" type="video/mp4" />
                    </video>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="newsletter-right-content d-block d-sm-flex align-items-center justify-content-center">
                            <div className="contact-btn">
                                <h5 id='audio-title'>Clicca qui per ascoltare</h5>
                                <audio controls>
  <source src="/videos/audio-home.ogg" type="audio/ogg" />
</audio>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CallToActionOne;