import PropTypes from 'prop-types';
import React from 'react'


const SEO = ( { title } ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{ title } | Adoc - Escuela de Italiano</title>
            <meta name="description" content="ADOC - Escuela de Italiano" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="keywords" content="escuela,italiano,aprender,cursos,italia,idiomas,semestre,estudiar,estudia,istituto,di,cultural,eventos,montevideo"/>
            <meta name="robots" content="follow"/>
            <meta charset="utf-8"/>
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;
