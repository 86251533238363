import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import CourseFour from './pages/course/CourseFour';
import HomeOne from './pages/homepages/HomeOne';
import AboutUsThree from './pages/innerpages/AboutUsThree';
import ContactUs from './pages/innerpages/ContactUs';
import Error from './pages/innerpages/Error';

// Import Css Here 
import './assets/scss/style.scss';

function App() {
    return (
        <Router>
            <ScrollToTop>
                <Routes>
                    <Route exact path='/' element={<HomeOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/inicio'}`} element={<HomeOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/cooperativa'}`} element={<AboutUsThree/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/contacto'}`} element={<ContactUs/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/cursos'}`} element={<CourseFour/>}/>
                    <Route path='*' element={<Error/>}/>
                </Routes>
            </ScrollToTop>
        </Router>
    );
}

export default App;
